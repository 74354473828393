import React, { useState, useEffect, useRef, useCallback } from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../firebase';
import HillChart from './HillChart';
import Controls from './Controls';

// Debounce function
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const HillChartPage = () => {
    const [dots, setDots] = useState([]);
    const hillChartRef = useRef(null);

    useEffect(() => {
        // Fetch dots from Firestore when component mounts
        const fetchDots = async () => {
            const querySnapshot = await getDocs(collection(db, "dots"));
            const fetchedDots = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setDots(fetchedDots);
        };
        fetchDots();
    }, []);

    const addDot = async (label) => {
        if (hillChartRef.current) {
            const newDot = {
                label,
                position: 0,
                color: getRandomColor()
            };
            const docRef = await addDoc(collection(db, "dots"), newDot);
            setDots([...dots, { ...newDot, id: docRef.id }]);
        }
    };

    const updateDotPositionInFirestore = async (dotId, position) => {
        await updateDoc(doc(db, "dots", dotId), { position });
    };

    const debouncedUpdateFirestore = useCallback(
        debounce(updateDotPositionInFirestore, 500),
        []
    );

    const updateDotPosition = (dotId, position) => {
        if (typeof position === 'number' && isFinite(position)) {
            setDots(dots.map(dot => dot.id === dotId ? { ...dot, position } : dot));
            debouncedUpdateFirestore(dotId, position);
        } else {
            console.warn(`Attempted to update dot ${dotId} with invalid position:`, position);
        }
    };

    const removeDot = async (dotId) => {
        await deleteDoc(doc(db, "dots", dotId));
        setDots(dots.filter(dot => dot.id !== dotId));
    };

    const getRandomColor = () => {
        return '#' + Math.floor(Math.random()*16777215).toString(16);
    };

    return (
        <div>
            <HillChart 
                ref={hillChartRef}
                dots={dots}
                updateDotPosition={updateDotPosition}
                removeDot={removeDot}
            />
            <Controls addDot={addDot} />
        </div>
    );
};

export default HillChartPage;