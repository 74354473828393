import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAgZR1iDepw5lXLZv6Ck5wjPA0FZ3b8-EM",
    authDomain: "hill-charts-8b0d3.firebaseapp.com",
    projectId: "hill-charts-8b0d3",
    storageBucket: "hill-charts-8b0d3.appspot.com",
    messagingSenderId: "864350282321",
    appId: "1:864350282321:web:f624d1aa4cb33ff7814e70",
    measurementId: "G-Y2G08MB3W4"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };