import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import './HillChart.css';

const HillChart = forwardRef(({ dots, updateDotPosition, removeDot }, ref) => {
    const svgRef = useRef(null);
    const hillPathRef = useRef(null);

    useImperativeHandle(ref, () => ({
        // You can add methods here if needed
    }));

    const getMousePositionOnSVG = (evt) => {
        const CTM = svgRef.current.getScreenCTM();
        return {
            x: (evt.clientX - CTM.e) / CTM.a,
            y: (evt.clientY - CTM.f) / CTM.d
        };
    };

    const getClosestPointOnPath = (path, point) => {
        const totalLength = path.getTotalLength();
        let closestLength = 0;
        let closestDistance = Infinity;
        const numSamples = 100;
        for (let i = 0; i <= numSamples; i++) {
            let testLength = (i / numSamples) * totalLength;
            let testPoint = path.getPointAtLength(testLength);
            let dx = testPoint.x - point.x;
            let dy = testPoint.y - point.y;
            let distance = dx * dx + dy * dy; // squared distance
            if (distance < closestDistance) {
                closestDistance = distance;
                closestLength = testLength;
            }
        }
        return closestLength;
    };

    return (
        <div>
            <h1>Hill Chart Visualization</h1>
            <div id="hill-chart-container">
                <svg ref={svgRef} id="hill-chart" width="800" height="400">
                    <path ref={hillPathRef} id="hill-path" d="M100,300 Q400,50 700,300" stroke="black" strokeWidth="2" fill="none" />
                    <line x1="400" y1="50" x2="400" y2="300" stroke="black" strokeDasharray="5,5" />
                    <text x="200" y="320" textAnchor="middle">Figuring Things Out</text>
                    <text x="600" y="320" textAnchor="middle">Making it Happen</text>
                    {dots.map(dot => (
                        <Dot
                            key={dot.id}
                            dot={dot}
                            hillPath={hillPathRef.current}
                            updateDotPosition={updateDotPosition}
                            removeDot={removeDot}
                            getMousePositionOnSVG={getMousePositionOnSVG}
                            getClosestPointOnPath={getClosestPointOnPath}
                        />
                    ))}
                </svg>
            </div>
        </div>
    );
});

const Dot = ({ dot, hillPath, updateDotPosition, removeDot, getMousePositionOnSVG, getClosestPointOnPath }) => {
    const dotRef = useRef(null);

    const handleMouseDown = (e) => {
        // Check if it's not a right-click (button 2)
        if (e.button !== 2) {
            e.preventDefault();
            console.log('mouse down', dot.id);
            const onMouseMove = (e) => {
                const mousePos = getMousePositionOnSVG(e);
                const position = getClosestPointOnPath(hillPath, mousePos);
                updateDotPosition(dot.id, position);
            };

            const onMouseUp = () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        if (window.confirm('Remove this dot?')) {
            removeDot(dot.id);
        }
    };

    let transform = 'translate(0, 0)';
    
    if (hillPath && typeof dot.position === 'number' && isFinite(dot.position)) {
        const point = hillPath.getPointAtLength(dot.position);
        console.log('point', point);
        transform = `translate(${point.x}, ${point.y})`;
    } else {
        console.warn(`Invalid position for dot ${dot.id}:`, dot.position);
    }

    return (
        <g 
            ref={dotRef} 
            transform={transform} 
            data-position={dot.position} 
            onMouseDown={handleMouseDown} 
            onContextMenu={handleContextMenu}
        >
            <circle r="10" fill={dot.color} cursor="pointer" />
            <text textAnchor="middle" y="-15" className="dot-label">{dot.label}</text>
        </g>
    );
};

export default HillChart;