import React, { useState } from 'react';

const Controls = ({ addDot }) => {
    const [label, setLabel] = useState('');

    const handleAddDot = () => {
        addDot(label);
        setLabel('');
    };

    return (
        <div id="controls">
            <input
                type="text"
                id="dot-label"
                placeholder="Dot Label"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
            />
            <button id="add-dot-btn" onClick={handleAddDot}>Add Dot</button>
        </div>
    );
};

export default Controls;