import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import HillChartPage from './components/HillChartPage';
import './App.css';

const App = () => {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={
                        <div>
                            <h1>Some stuff I've made</h1>
                            <nav>
                                <Link to="/hillchart">Hill Chart</Link>
                            </nav>
                        </div>
                    } />
                    <Route path="/hillchart" element={<HillChartPage />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;